footer {
  padding: 50px 0 0;
  background: rgb(95, 95, 95);
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  footer {
    padding-top: 30px;
  }
}
footer p {
  margin-bottom: 0;
}
footer h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 1;
  position: relative;
}
footer h3:after {
  content: "";
  display: block;
  width: 70px;
  height: 2px;
  margin: 0 auto;
  background: #01909e;
  margin-top: 12px;
}
footer h3.footer-title {
  margin-bottom: 20px;
}
footer .footer-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  text-align: center;
  background: rgb(130, 175, 95);
  color: #000000;
}
@media screen and (max-width: 767px) {
  footer .footer-bar {
    margin-top: 30px;
  }
  footer .footer-bar p {
    font-size: 13px;
  }
}
footer .footer-bar span {
  color: #27496e;
  font-size: 15px;
  font-weight: 400;
}
footer.footer-title:after {
  margin-left: auto;
  margin-right: auto;
}