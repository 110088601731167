.nph-datatable {
  width: 100%;
  /*	table-layout: fixed;
  word-wrap: break-word;*/
  /*		table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc {
  	padding-right: 0.75em !important;

  	&:before,
  	&:after {
  		display: none;
  	}
  }*/
  /*	.dataTables_wrapper .dataTables_filter {
  		float: unset !important;
  	}
  */
}
.nph-datatable input {
  margin-bottom: 0;
}
.nph-datatable th {
  background-color: rgb(34, 107, 158) !important;
  color: white !important;
  vertical-align: middle !important;
  line-height: 20px;
}
.nph-datatable th select {
  margin-bottom: 0;
}
.nph-datatable th.subheader {
  background-color: rgba(41, 38, 97, 0.5019607843) !important;
  color: black !important;
  font-weight: normal !important;
  padding: 0.5rem 0.75rem;
}
.nph-datatable table.dataTable tbody td {
  vertical-align: middle;
  text-align: center;
}
.nph-datatable table.dataTable tbody td:hover {
  cursor: pointer;
}
.nph-datatable table.dataTable tbody td.details-control {
  background: url("/images/util/details_open.png") no-repeat center center;
  cursor: pointer;
}
.nph-datatable table.dataTable tbody tr.shown td.details-control {
  background: url("/images/util/details_close.png") no-repeat center center;
}
.nph-datatable table.dataTable tbody tr.detail-row td {
  vertical-align: top;
}
.nph-datatable thead .sorting:before, .nph-datatable thead .sorting:after,
.nph-datatable thead .sorting_asc:before, .nph-datatable thead .sorting_asc:after,
.nph-datatable thead .sorting_desc:before, .nph-datatable thead .sorting_desc:after {
  display: none !important;
}
.nph-datatable thead tr > th:not(.sorting_disabled) {
  padding-right: 0.75em !important;
}
.nph-datatable div .dataTables_wrapper div.dataTables_filter {
  display: inline-block;
  float: right;
}
.nph-datatable .dataTables_wrapper .dataTables_filter {
  display: inline-block;
  float: unset !important;
}
.nph-datatable.right-filter .dataTables_wrapper .dataTables_filter {
  float: right !important;
}
.nph-datatable.right-filter .dataTables_wrapper .dataTables_filter label {
  padding-right: 0;
  font-size: 12px;
}
.nph-datatable div.dataTables_wrapper div.dataTables_filter input {
  margin-bottom: 0 !important;
}
.nph-datatable .top-controls .table-message {
  font-size: 12px;
  line-height: 10px;
  margin-top: 5px;
  font-style: italic;
}
.nph-datatable label {
  padding-right: 10px;
}
.nph-datatable input {
  margin: 0;
}
.nph-datatable select {
  margin: 0;
}
.nph-datatable .form-group {
  margin-bottom: 0.5em;
}
.nph-datatable .dt-buttons {
  float: right;
  margin-left: 10px;
}
.nph-datatable .dt-buttons button {
  padding: 2px 11px 1px 11px;
  height: 32px;
  width: 36px;
  max-width: 36px;
  background-color: #616161;
}
.nph-datatable .dt-buttons button:hover {
  /*color: black;*/
  background-color: rgb(34, 107, 158);
}

.bootstrap-select .filter-option {
  font-size: 14px;
  margin-right: 8px;
}
.bootstrap-select .dropdown-menu li.disabled a {
  font-size: 11px;
}
@media screen and (max-width: 767px) {
  .bootstrap-select .dropdown-menu {
    max-width: 100%;
  }
}
.bootstrap-select.transparent .btn-light {
  background-color: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}
.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  left: 12px;
}
.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 10px;
  margin-left: 8px;
}