section {
  padding: 70px 0;
}

.image-row {
  margin: 75px 0;
}

a:hover, aactive {
  color: rgb(34, 107, 158);
  text-decoration: none;
}

.bg-theme {
  background-color: rgb(130, 175, 95) !important;
}

.bg-theme-90 {
  background: rgba(130, 175, 95, 0.9);
}

.bg-theme-80 {
  background: rgba(130, 175, 95, 0.8);
}

.bg-theme-75 {
  background: rgba(130, 175, 95, 0.75);
}

.bg-gainsboro {
  background-color: #dae1e7;
}

.bg-ltgrey {
  background-color: #f2f2f2;
}

.theme-overlay[data-overlay-dark]:before,
.theme-overlay[data-overlay-light]:before {
  background: #27496e;
}

.text-theme-primary {
  color: rgb(34, 107, 158);
}

.text-theme-secondary {
  color: rgb(130, 175, 95);
}

.text-content-big p {
  font-weight: 400;
  line-height: 200%;
  margin-bottom: 25px;
  font-size: 15px;
}

.shadow-primary {
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.line-through {
  text-decoration: line-through;
}

.readmore {
  font-weight: 700;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.readmore:after {
  content: "\f105";
  font-size: 16px;
  vertical-align: top;
  font-family: Font Awesome\ 5 Free;
  padding-left: 8px;
  vertical-align: middle;
}
.readmore :hover {
  color: #27496e;
}

.link-icon {
  margin-left: 7px;
}

ul.primary-list {
  list-style-position: outside;
  margin-left: 10px;
}
ul.primary-list li {
  list-style: none;
  margin-top: 0.75rem;
  padding-left: 15px;
  position: relative;
}
ul.primary-list li .icon {
  font-weight: 900;
  margin-right: 10px;
  color: #01909E;
}
ul.secondary-list {
  list-style-position: inside;
  margin-left: 30px;
}
ul.secondary-list li {
  list-style: disc;
  /*margin-top: .75rem;*/
  /*padding-left: 15px;*/
  /*position: relative;*/
}

.modal-full {
  max-width: 94vw;
}

/*
.top-login {
	padding: 0;
	float: right;
	margin: 0;

	li {
		font-size: 14px;
		list-style-type: none;
		float: left;
		text-align: center;
		margin: 0;
		padding: 0 7px;

		&:last-child {
			padding-right: 0;

			a {
				padding-right: 0;
			}
		}

		a {
			color: #fff;
			line-height: 28px;
			transition-duration: .3s;
			padding: 0 3px;

			&:hover {
				color: #27496e;
			}
		}
	}
}
*/
/*
.blockquote {
	background: #f1f1f1;
	border-left: 4px solid #27496e;
	font-size: 16px;
	font-weight: 500;
	margin: 25px 0;
	padding: 20px 30px 30px;

	p {
		font-size: 15px;
		font-weight: 500;
		line-height: 190%;
		color: #232323;
		letter-spacing: .5px;
	}

	h4 {
		font-size: 20px;
		font-weight: 400;
		color: #232323;
		letter-spacing: .5px;
	}
}
*/
@media screen and (max-width: 575px) {
  .no-mobile-margin-15px-bottom {
    margin-bottom: 0 !important;
  }
  .mobile-margin-15px-bottom {
    margin-bottom: 15px;
  }
  .mobile-margin-20px-bottom {
    margin-bottom: 20px;
  }
  .mobile-margin-30px-bottom {
    margin-bottom: 30px;
  }
}
.img-border {
  border: 1px solid #191919;
  background-color: #191919;
}

img.no-download {
  pointer-events: none;
}