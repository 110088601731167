/* ===================================
    Fonts
====================================== */
/* ===================================
    Reset
====================================== */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 14px;
  color: #616161;
  font-weight: 400;
  line-height: 24px;
}

body, html {
  -moz-osx-font-smoothing: grayscale;
}

a, a:active {
  color: #27496E;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

a:hover, a:active, a:focus {
  color: #232323;
  text-decoration: underline;
}

a:focus, a:active, button:focus, button:active, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

input, textarea, select {
  border: 1px solid #d1d1d1;
  font-size: 14px;
  padding: 8px 15px;
  width: 100%;
  margin: 0 0 15px 0;
  max-width: 100%;
  resize: none;
}

input[type=button], input[type=text], input[type=email], input[type=search], input[type=password], textarea, input[type=submit] {
  -webkit-appearance: none;
  outline: none;
}

input:focus, textarea:focus {
  border-color: #585858;
  outline: none;
}

input[type=button]:focus {
  outline: none;
}

select::-ms-expand {
  display: none;
}

iframe {
  border: 0;
}

p {
  margin: 0 0 20px;
  /*word-spacing: 1px;*/
}

b, strong {
  font-weight: 600;
}

.height-50 {
  height: 50%;
}

.height-100 {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.last-paragraph-no-margin p:last-of-type {
  margin-bottom: 0;
}

.alt-font strong {
  font-weight: 700;
}

.white-space {
  white-space: normal;
}

.word-wrap {
  word-wrap: break-word;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
          align-items: center;
}

ul, ol, dl {
  list-style-position: outside;
}

ul li {
  list-style: none;
}

::-moz-selection {
  color: #000;
  background: #dbdbdb;
}

::selection {
  color: #000;
  background: #dbdbdb;
}

::-moz-selection {
  color: #000;
  background: #dbdbdb;
}

::-webkit-input-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
}

::-moz-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #6f6f6f;
  text-overflow: ellipsis;
  opacity: 1;
}

.half-gutter {
  margin: 0 -8px;
}

.half-gutter > [class*=col-] {
  padding: 7px;
}

/* ===================================
    Typography
====================================== */
/* font family */
.alt-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.main-font {
  font-family: "Open Sans", sans-serif;
}

/* heading */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 0;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #232323;
  line-height: normal;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 26px;
}

h6 {
  font-size: 22px;
}

/* font size */
.font-size11 {
  font-size: 11px;
}

.font-size12 {
  font-size: 12px;
}

.font-size13 {
  font-size: 13px;
}

.font-size14 {
  font-size: 14px;
}

.font-size15 {
  font-size: 15px;
}

.font-size16 {
  font-size: 16px;
}

.font-size17 {
  font-size: 17px;
}

.font-size18 {
  font-size: 18px;
}

.font-size19 {
  font-size: 19px;
}

.font-size20 {
  font-size: 20px;
}

.font-size22 {
  font-size: 22px;
}

.font-size24 {
  font-size: 24px;
}

.font-size26 {
  font-size: 26px;
}

.font-size28 {
  font-size: 28px;
}

.font-size30 {
  font-size: 30px;
}

.font-size32 {
  font-size: 32px;
}

.font-size34 {
  font-size: 34px;
}

.font-size36 {
  font-size: 36px;
}

.font-size38 {
  font-size: 38px;
}

.font-size40 {
  font-size: 40px;
}

.font-size42 {
  font-size: 42px;
}

.font-size44 {
  font-size: 44px;
}

.font-size46 {
  font-size: 46px;
}

.font-size48 {
  font-size: 48px;
}

.font-size50 {
  font-size: 50px;
}

.font-size55 {
  font-size: 55px;
}

.font-size60 {
  font-size: 60px;
}

.font-size80 {
  font-size: 80px;
}

.font-size100 {
  font-size: 100px;
}

.font-size120 {
  font-size: 120px;
}

.font-size130 {
  font-size: 130px;
}

/* text color */
.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-extra-dark-gray {
  color: #232323;
}

.text-dark-gray {
  color: #626262;
}

.text-extra-medium-gray {
  color: #757575;
}

.text-medium-gray {
  color: #939393;
}

.text-extra-light-gray {
  color: #b7b7b7;
}

.text-light-gray {
  color: #d6d5d5;
}

.text-very-light-gray {
  color: #ededed;
}

.text-default-color {
  color: #6f6f6f;
}

/* letter spacing */
.no-letter-spacing {
  letter-spacing: 0px;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

/* font weight */
.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

/* line height */
.line-height-normal {
  line-height: normal;
}

.line-height-10 {
  line-height: 10px;
}

.line-height-13 {
  line-height: 13px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-35 {
  line-height: 35px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-45 {
  line-height: 45px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-55 {
  line-height: 55px;
}

.line-height-60 {
  line-height: 60px;
}

.line-height-65 {
  line-height: 65px;
}

.line-height-70 {
  line-height: 70px;
}

.line-height-75 {
  line-height: 75px;
}

.line-height-80 {
  line-height: 80px;
}

.line-height-85 {
  line-height: 85px;
}

.line-height-90 {
  line-height: 90px;
}

.line-height-95 {
  line-height: 95px;
}

.line-height-100 {
  line-height: 100px;
}

.line-height-110 {
  line-height: 110px;
}

.line-height-120 {
  line-height: 120px;
}

/* ===================================
    Background color
====================================== */
.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: #fff;
}

.bg-black {
  background-color: #000;
}

.bg-extra-dark-gray {
  background-color: #1c1c1c;
}

.bg-dark-black {
  background-color: #232323;
}

.bg-medium-dark-gray {
  background-color: #191919;
}

.bg-dark-gray {
  background-color: #757575;
}

.bg-extra-medium-gray {
  background-color: #939393;
}

.bg-medium-gray {
  background-color: #dbdbdb;
}

.bg-extra-light-gray {
  background-color: #e0e0e0;
}

.bg-medium-light-gray {
  background-color: #ededed;
}

.bg-light-gray {
  background-color: #f7f7f7;
}

.bg-very-light-gray {
  background-color: #fafafa;
}

.bg-grey {
  background-color: #f9f9f9;
}

.bg-red {
  background-color: #ed1b24;
}

.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.3);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(37%, rgba(255, 255, 255, 0)), color-stop(96%, rgb(255, 255, 255)), color-stop(100%, rgb(255, 255, 255)));
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(37%, rgba(255, 255, 255, 0)), color-stop(96%, rgb(255, 255, 255)), to(rgb(255, 255, 255)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgb(255, 255, 255) 96%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1 );
}

.bg-transparent-black {
  background-color: rgba(0, 0, 0, 0);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(37%, rgba(0, 0, 0, 0)), color-stop(96%, rgb(0, 0, 0)), color-stop(100%, rgb(0, 0, 0)));
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), color-stop(37%, rgba(0, 0, 0, 0)), color-stop(96%, rgb(0, 0, 0)), to(rgb(0, 0, 0)));
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgb(0, 0, 0) 96%, rgb(0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#000000", GradientType=1 );
}

.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.85);
}

.bg-black-opacity {
  background-color: rgba(0, 0, 0, 0.85);
}

.bg-black-opacity-light {
  background-color: rgba(0, 0, 0, 0.5);
}

/* ===================================
    Custom
====================================== */
.center-col {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

section {
  padding: 90px 0;
  overflow: hidden;
  background: #fff;
}

.big-input, .big-textarea, .big-select select {
  padding: 18px 25px;
  font-size: 14px;
  border-radius: 0;
}

.medium-input, .medium-textarea, .medium-select select {
  padding: 12px 20px;
  font-size: 14px;
  line-height: normal;
  border-radius: 0;
}

.small-input, .small-textarea {
  padding: 12px 15px;
  font-size: 11px;
  line-height: normal;
}

.small-select select {
  padding: 15px 15px;
  line-height: normal;
}

.medium-input-light, .medium-textarea-light, .medium-select-light select {
  padding: 12px 25px;
  font-size: 12px;
  line-height: normal;
}

.required-error {
  border: 1px solid #ee2323 !important;
}

.input-border-bottom.required-error {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #ee2323 !important;
}

#success-contact-form {
  clear: both;
  margin: 0 15px 15px;
  text-align: center;
  border: 1px solid #1a940a;
  color: #1a940a;
  padding: 2px 0;
  display: none;
  font-size: 11px;
}

/* separator */
.separator-line-vertical-extra-small {
  width: 1px;
  height: 8px;
}

.separator-line-vertical-small-thick {
  width: 17px;
  height: 3px;
}

.separator-line-vertical-small {
  width: 1px;
  height: 13px;
}

.separator-line-vertical-small-thick2 {
  width: 64px;
  height: 7px;
}

.separator-line-vertical-large {
  width: 1px;
  height: 20px;
}

.separator-line-vertical-extra-large {
  width: 1px;
  height: 30px;
}

.separator-line-vertical-medium-thick-full {
  width: 8px;
  height: 40px;
}

.separator-line-vertical-large-thick {
  width: 109px;
  height: 11px;
}

.separator-line-horizontal-medium-light {
  width: 36px;
  height: 3px;
}

.separator-line-horizontal-medium-light2 {
  width: 50px;
  height: 1px;
}

.separator-line-horizontal-medium-light3 {
  width: 18%;
  height: 1px;
}

.separator-line-horizontal-medium-thick {
  width: 50px;
  height: 5px;
}

.separator-line-horizontal-full {
  width: 100%;
  height: 1px;
}

/* divider */
.divider-full {
  width: 100%;
  height: 1px;
  display: inline-block;
}

/* opacity */
.opacity-very-light {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  top: 0;
  left: 0;
}

.opacity-light {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  top: 0;
  left: 0;
}

.opacity-extra-medium {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
}

.opacity-medium {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.75;
  top: 0;
  left: 0;
}

.opacity-full {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  top: 0;
  left: 0;
}

.opacity-full-dark {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  top: 0;
  left: 0;
}

.opacity1 {
  opacity: 0.1;
}

.opacity2 {
  opacity: 0.2;
}

.opacity3 {
  opacity: 0.3;
}

.opacity4 {
  opacity: 0.4;
}

.opacity5 {
  opacity: 0.5;
}

.opacity6 {
  opacity: 0.6;
}

.opacity7 {
  opacity: 0.7;
}

.opacity8 {
  opacity: 0.8;
}

.opacity9 {
  opacity: 0.9;
}

/* box-shadow */
.btn-shadow {
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.box-shadow-light {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.box-shadow {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.box-shadow-dark {
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.box-shadow-large {
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.box-shadow-primary {
  -webkit-box-shadow: 0 18px 38px rgba(0, 0, 0, 0.2);
  box-shadow: 0 18px 38px rgba(0, 0, 0, 0.2);
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* z-index */
.z-index-1111 {
  z-index: 1111;
}

.z-index-111 {
  z-index: 111;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-9 {
  z-index: 9;
}

.z-index-0 {
  z-index: 0;
}

.z-index-minus2 {
  z-index: -2;
}

/* ===================================
    Border
====================================== */
.border-all {
  border: 1px solid #ededed;
}

/* boder width */
.border-width-1 {
  border-width: 1px !important;
}

.border-width-2 {
  border-width: 2px !important;
}

.border-width-3 {
  border-width: 3px !important;
}

.border-width-4 {
  border-width: 4px !important;
}

.border-width-5 {
  border-width: 5px !important;
}

.border-width-6 {
  border-width: 6px !important;
}

.border-width-7 {
  border-width: 7px !important;
}

.border-width-8 {
  border-width: 8px !important;
}

.border-width-9 {
  border-width: 9px !important;
}

.border-width-10 {
  border-width: 10px !important;
}

.border-width-20 {
  border-width: 20px !important;
}

/* boder align */
.border-top {
  border-top: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-left {
  border-left: 1px solid;
}

.border-right {
  border-right: 1px solid;
}

.border-lr {
  border-left: 1px solid;
  border-right: 1px solid;
}

.border-tb {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

/* border color */
.border-color-white {
  border-color: #fff !important;
}

.border-color-black {
  border-color: #000 !important;
}

.border-color-extra-dark-gray {
  border-color: #232323 !important;
}

.border-color-medium-dark-gray {
  border-color: #363636 !important;
}

.border-color-dark-gray {
  border-color: #939393 !important;
}

.border-color-extra-medium-gray {
  border-color: #dbdbdb !important;
}

.border-color-medium-gray {
  border-color: #e4e4e4 !important;
}

.border-color-extra-light-gray {
  border-color: #ededed !important;
}

.border-color-light-gray {
  border-color: #f5f5f5 !important;
}

.border-color-light-white {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-color-light-black {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

/* boder style */
.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

.border-groove {
  border-style: groove !important;
}

.border-ridge {
  border-style: ridge !important;
}

.border-inset {
  border-style: inset !important;
}

.border-outset {
  border-style: outset !important;
}

.border-none {
  border-style: none !important;
}

.border-hidden {
  border-style: hidden !important;
}

.border-transperent {
  border-color: transparent !important;
}

.no-border-all {
  border: none !important;
}

.no-border-top {
  border-top: 0 !important;
}

.no-border-bottom {
  border-bottom: 0 !important;
}

.no-border-right {
  border-right: 0 !important;
}

.no-border-left {
  border-left: 0 !important;
}

/* border for inner box */
.inner-border {
  border: 8px solid rgba(255, 255, 255, 0.35);
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  left: 20px;
  top: 20px;
  position: absolute;
}

.inner-border2 {
  border: 8px solid rgba(255, 255, 255, 0.35);
  height: calc(100% - 80px);
  width: calc(100% - 110px);
  left: 55px;
  top: 40px;
  position: absolute;
}

/* transparent border */
.border-black-light {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.border-white-light {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

/* border radius */
.border-radius-1 {
  border-radius: 1px;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-6 {
  border-radius: 6px;
}

.border-radius-7 {
  border-radius: 7px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-9 {
  border-radius: 9px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-50 {
  border-radius: 50%;
}

.border-radius-100 {
  border-radius: 100%;
}

.border-radius-none {
  border-radius: 0 !important;
}

/*================================
    Responsive Media Query
================================*/
@media screen and (max-width: 1199px) {
  section {
    padding: 70px 0;
  }
  .md-height-auto {
    height: auto;
  }
  /* typography */
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 18px;
    padding: 0;
    letter-spacing: 0;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 28px;
  }
  h5 {
    font-size: 24px;
  }
  h6 {
    font-size: 20px;
  }
  /* line height */
  .md-line-height-normal {
    line-height: normal;
  }
  .md-line-height-10 {
    line-height: 10px;
  }
  .md-line-height-13 {
    line-height: 13px;
  }
  .md-line-height-18 {
    line-height: 18px;
  }
  .md-line-height-20 {
    line-height: 20px;
  }
  .md-line-height-24 {
    line-height: 24px;
  }
  .md-line-height-22 {
    line-height: 22px;
  }
  .md-line-height-26 {
    line-height: 26px;
  }
  .md-line-height-28 {
    line-height: 28px;
  }
  .md-line-height-30 {
    line-height: 30px;
  }
  .md-line-height-35 {
    line-height: 35px;
  }
  .md-line-height-40 {
    line-height: 40px;
  }
  .md-line-height-45 {
    line-height: 45px;
  }
  .md-line-height-50 {
    line-height: 50px;
  }
  .md-line-height-55 {
    line-height: 55px;
  }
  .md-line-height-60 {
    line-height: 60px;
  }
  .md-line-height-65 {
    line-height: 65px;
  }
  .md-line-height-70 {
    line-height: 70px;
  }
  .md-line-height-75 {
    line-height: 75px;
  }
  .md-line-height-80 {
    line-height: 80px;
  }
  .md-line-height-85 {
    line-height: 85px;
  }
  .md-line-height-90 {
    line-height: 90px;
  }
  .md-line-height-95 {
    line-height: 95px;
  }
  .md-line-height-100 {
    line-height: 100px;
  }
  .md-line-height-110 {
    line-height: 110px;
  }
  .md-line-height-120 {
    line-height: 120px;
  }
  /* font size */
  .md-font-size11 {
    font-size: 11px;
  }
  .md-font-size12 {
    font-size: 12px;
  }
  .md-font-size13 {
    font-size: 13px;
  }
  .md-font-size14 {
    font-size: 14px;
  }
  .md-font-size15 {
    font-size: 15px;
  }
  .md-font-size16 {
    font-size: 16px;
  }
  .md-font-size17 {
    font-size: 17px;
  }
  .md-font-size18 {
    font-size: 18px;
  }
  .md-font-size19 {
    font-size: 19px;
  }
  .md-font-size20 {
    font-size: 20px;
  }
  .md-font-size22 {
    font-size: 22px;
  }
  .md-font-size24 {
    font-size: 24px;
  }
  .md-font-size26 {
    font-size: 26px;
  }
  .md-font-size28 {
    font-size: 28px;
  }
  .md-font-size30 {
    font-size: 30px;
  }
  .md-font-size32 {
    font-size: 32px;
  }
  .md-font-size34 {
    font-size: 34px;
  }
  .md-font-size36 {
    font-size: 36px;
  }
  .md-font-size38 {
    font-size: 38px;
  }
  .md-font-size40 {
    font-size: 40px;
  }
  .md-font-size42 {
    font-size: 42px;
  }
  .md-font-size44 {
    font-size: 44px;
  }
  .md-font-size46 {
    font-size: 46px;
  }
  .md-font-size48 {
    font-size: 48px;
  }
  .md-font-size50 {
    font-size: 50px;
  }
  .md-font-size55 {
    font-size: 55px;
  }
  .md-font-size60 {
    font-size: 60px;
  }
  .md-font-size80 {
    font-size: 80px;
  }
  .md-font-size100 {
    font-size: 100px;
  }
  .md-font-size120 {
    font-size: 120px;
  }
  .md-font-size130 {
    font-size: 130px;
  }
}
@media screen and (max-width: 991px) {
  .sm-height-auto {
    height: auto;
  }
  /* typography */
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 16px;
    padding: 0;
    letter-spacing: 0;
  }
  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 26px;
  }
  h5 {
    font-size: 22px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    margin: 0 0 20px;
  }
  /* font size */
  .sm-font-size11 {
    font-size: 11px;
  }
  .sm-font-size12 {
    font-size: 12px;
  }
  .sm-font-size13 {
    font-size: 13px;
  }
  .sm-font-size14 {
    font-size: 14px;
  }
  .sm-font-size15 {
    font-size: 15px;
  }
  .sm-font-size16 {
    font-size: 16px;
  }
  .sm-font-size17 {
    font-size: 17px;
  }
  .sm-font-size18 {
    font-size: 18px;
  }
  .sm-font-size19 {
    font-size: 19px;
  }
  .sm-font-size20 {
    font-size: 20px;
  }
  .sm-font-size22 {
    font-size: 22px;
  }
  .sm-font-size24 {
    font-size: 24px;
  }
  .sm-font-size26 {
    font-size: 26px;
  }
  .sm-font-size28 {
    font-size: 28px;
  }
  .sm-font-size30 {
    font-size: 30px;
  }
  .sm-font-size32 {
    font-size: 32px;
  }
  .sm-font-size34 {
    font-size: 34px;
  }
  .sm-font-size36 {
    font-size: 36px;
  }
  .sm-font-size38 {
    font-size: 38px;
  }
  .sm-font-size40 {
    font-size: 40px;
  }
  .sm-font-size42 {
    font-size: 42px;
  }
  .sm-font-size44 {
    font-size: 44px;
  }
  .sm-font-size46 {
    font-size: 46px;
  }
  .sm-font-size48 {
    font-size: 48px;
  }
  .sm-font-size50 {
    font-size: 50px;
  }
  .sm-font-size55 {
    font-size: 55px;
  }
  .sm-font-size60 {
    font-size: 60px;
  }
  .sm-font-size80 {
    font-size: 80px;
  }
  .sm-font-size100 {
    font-size: 100px;
  }
  .sm-font-size120 {
    font-size: 120px;
  }
  .sm-font-size130 {
    font-size: 130px;
  }
  /* line height */
  .sm-line-height-normal {
    line-height: normal;
  }
  .sm-line-height-10 {
    line-height: 10px;
  }
  .sm-line-height-13 {
    line-height: 13px;
  }
  .sm-line-height-18 {
    line-height: 18px;
  }
  .sm-line-height-20 {
    line-height: 20px;
  }
  .sm-line-height-24 {
    line-height: 24px;
  }
  .sm-line-height-22 {
    line-height: 22px;
  }
  .sm-line-height-26 {
    line-height: 26px;
  }
  .sm-line-height-28 {
    line-height: 28px;
  }
  .sm-line-height-30 {
    line-height: 30px;
  }
  .sm-line-height-35 {
    line-height: 35px;
  }
  .sm-line-height-40 {
    line-height: 40px;
  }
  .sm-line-height-45 {
    line-height: 45px;
  }
  .sm-line-height-50 {
    line-height: 50px;
  }
  .sm-line-height-55 {
    line-height: 55px;
  }
  .sm-line-height-60 {
    line-height: 60px;
  }
  .sm-line-height-65 {
    line-height: 65px;
  }
  .sm-line-height-70 {
    line-height: 70px;
  }
  .sm-line-height-75 {
    line-height: 75px;
  }
  .sm-line-height-80 {
    line-height: 80px;
  }
  .sm-line-height-85 {
    line-height: 85px;
  }
  .sm-line-height-90 {
    line-height: 90px;
  }
  .sm-line-height-95 {
    line-height: 95px;
  }
  .sm-line-height-100 {
    line-height: 100px;
  }
  .sm-line-height-110 {
    line-height: 110px;
  }
  .sm-line-height-120 {
    line-height: 120px;
  }
  /* custom */
  section {
    padding: 50px 0;
  }
  .sm-center-col {
    float: none;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .xs-height-auto {
    height: auto;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 14px;
    padding: 0;
    letter-spacing: 0;
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
  /* font size */
  .xs-font-size11 {
    font-size: 11px;
  }
  .xs-font-size12 {
    font-size: 12px;
  }
  .xs-font-size13 {
    font-size: 13px;
  }
  .xs-font-size14 {
    font-size: 14px;
  }
  .xs-font-size15 {
    font-size: 15px;
  }
  .xs-font-size16 {
    font-size: 16px;
  }
  .xs-font-size17 {
    font-size: 17px;
  }
  .xs-font-size18 {
    font-size: 18px;
  }
  .xs-font-size19 {
    font-size: 19px;
  }
  .xs-font-size20 {
    font-size: 20px;
  }
  .xs-font-size22 {
    font-size: 22px;
  }
  .xs-font-size24 {
    font-size: 24px;
  }
  .xs-font-size26 {
    font-size: 26px;
  }
  .xs-font-size28 {
    font-size: 28px;
  }
  .xs-font-size30 {
    font-size: 30px;
  }
  .xs-font-size32 {
    font-size: 32px;
  }
  .xs-font-size34 {
    font-size: 34px;
  }
  .xs-font-size36 {
    font-size: 36px;
  }
  .xs-font-size38 {
    font-size: 38px;
  }
  .xs-font-size40 {
    font-size: 40px;
  }
  .xs-font-size42 {
    font-size: 42px;
  }
  .xs-font-size44 {
    font-size: 44px;
  }
  .xs-font-size46 {
    font-size: 46px;
  }
  .xs-font-size48 {
    font-size: 48px;
  }
  .xs-font-size50 {
    font-size: 50px;
  }
  .xs-font-size55 {
    font-size: 55px;
  }
  .xs-font-size60 {
    font-size: 60px;
  }
  .xs-font-size80 {
    font-size: 80px;
  }
  .xs-font-size100 {
    font-size: 100px;
  }
  .xs-font-size120 {
    font-size: 120px;
  }
  .xs-font-size130 {
    font-size: 130px;
  }
  /* line height */
  .xs-line-height-normal {
    line-height: normal;
  }
  .xs-line-height-10 {
    line-height: 10px;
  }
  .xs-line-height-13 {
    line-height: 13px;
  }
  .xs-line-height-18 {
    line-height: 18px;
  }
  .xs-line-height-20 {
    line-height: 20px;
  }
  .xs-line-height-24 {
    line-height: 24px;
  }
  .xs-line-height-22 {
    line-height: 22px;
  }
  .xs-line-height-26 {
    line-height: 26px;
  }
  .xs-line-height-28 {
    line-height: 28px;
  }
  .xs-line-height-30 {
    line-height: 30px;
  }
  .xs-line-height-35 {
    line-height: 35px;
  }
  .xs-line-height-40 {
    line-height: 40px;
  }
  .xs-line-height-45 {
    line-height: 45px;
  }
  .xs-line-height-50 {
    line-height: 50px;
  }
  .xs-line-height-55 {
    line-height: 55px;
  }
  .xs-line-height-60 {
    line-height: 60px;
  }
  .xs-line-height-65 {
    line-height: 65px;
  }
  .xs-line-height-70 {
    line-height: 70px;
  }
  .xs-line-height-75 {
    line-height: 75px;
  }
  .xs-line-height-80 {
    line-height: 80px;
  }
  .xs-line-height-85 {
    line-height: 85px;
  }
  .xs-line-height-90 {
    line-height: 90px;
  }
  .xs-line-height-95 {
    line-height: 95px;
  }
  .xs-line-height-100 {
    line-height: 100px;
  }
  .xs-line-height-110 {
    line-height: 110px;
  }
  .xs-line-height-120 {
    line-height: 120px;
  }
}